import ConnectivityIndicator from '../ConnectivityIndicator'

export default function VerbindungsDetails({ maschine, ...props }) {
  let isDisabled = false
  if (!maschine.presence
    || (!maschine.presence.connected && !maschine.presence.disconnected)
    || ['ausgebaut', 'Dataplane deaktiviert'].includes(maschine.machine_status)) {
    isDisabled = true
  }
  let isConnected = maschine.presence?.isConnected || false
  if (isDisabled) {
    isConnected = false
  }
  const connectedDate = isConnected
    ? maschine.presence?.connected?.dt
    : maschine.presence?.disconnected?.dt

  let isAjaDisabled = false
  if (!maschine.presence
    || (!maschine.presence['aja-connected'] && !maschine.presence['aja-disconnected'])
    || ['ausgebaut'].includes(maschine.machine_status)) {
    isAjaDisabled = true
  }
  let isAjaConnected = maschine.presence?.isAjaConnected || false
  if (isAjaDisabled) {
    isAjaConnected = false
  }
  let ajaConnectedDate = undefined
  if (maschine.presence) {
    ajaConnectedDate = isAjaConnected
      ? (maschine.presence['aja-connected']?.dt)
      : (maschine.presence['aja-disconnected']?.dt)
  }

  return (
    <div>
      <strong>Verbindung</strong><br />
      {maschine.machine_status && maschine.machine_status !== 'n/a' && maschine.machine_status !== 'aktiv'
        ? <>Status: {maschine.machine_status.replace('Dataplane', 'Sensor-Daten')}</>
        : ''
      }
      {maschine.presence
        ? <div>
          <Verbindung
            name="Sensor-Daten"
            isKnown={maschine.presence}
            isConnected={isConnected}
            connectedDate={connectedDate}
            isDisabled={isDisabled}
          />
          <Verbindung
            name="Fernwartung"
            isKnown={maschine.presence}
            isConnected={isAjaConnected}
            connectedDate={ajaConnectedDate}
            isDisabled={isAjaDisabled}
          />
        </div>
        : 'unbekannt'
      }
    </div>
  )
}

function Verbindung({ name, isKnown, isConnected, connectedDate, isDisabled, ...props }) {
  return (
    <div>
      {name}:{' '}
      {isKnown
        ? <>
          <ConnectivityIndicator name={name} isConnected={isConnected} isDisabled={isDisabled} />
          {' '}
          <TextWithTooltip tooltip={'seit ' + connectedDate}>seit {formatDateTimeSince(connectedDate)}
            {' '}
            {isConnected
              ? 'verbunden'
              : 'getrennt'}
          </TextWithTooltip>
        </>
        : 'n/a'
      }<br />
    </div>
  )
}

function TextWithTooltip({ children, tooltip, ...props }) {
  return (
    <span title={tooltip} className='psi-text-with-tooltip'>{children}</span>
  )
}

/** Returns duration since a past datetime as human-readable (German) string, using only the most significant unit (e.g., 1 year). */
function formatDateTimeSince(dt) {
  const date = new Date(dt);
  const seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " Jahren";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " Monaten";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " Tagen";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " Stunden";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " Minuten";
  }
  return Math.floor(seconds) + " Sekunden";
}

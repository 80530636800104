import * as React from 'react'
import { useParams } from 'react-router-dom'
// import './reports.css'

import { Amplify, API, Auth } from 'aws-amplify'
import config, { isDev } from '../config'
import { Breadcrumbs, getLinkToMaschineBaleStats } from '../LinkUtils'
import ConnectivityIndicator from '../components/ConnectivityIndicator'

Amplify.configure({
  API: {
    endpoints: [
      {
        name: 'maschinenApi',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
        }
      }
    ]
  }
})

export function BaleReportsList({ seriennummer, numDays, ...props }) {
  const [data, setData] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(null)

  React.useEffect(() => {
    const fetchData = async (seriennummer) => {
      try {
        const apiName = 'maschinenApi'
        const path = `/maschine/${seriennummer}/reports/bales?numDays=${numDays}`
        const myInit = {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
          }
        }
        const reports = await API.get(apiName, path, myInit)
        if (!reports || !reports.latest) {
          throw new Error(`Keine Daten für ${seriennummer} gefunden.`)
        }
        setData(reports)
        setError(null)
      } catch (err) {
        console.error('Error:', err)
        setData(null)
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }
    fetchData(seriennummer)
  }, [seriennummer])

  if (loading) {
    return <div>Lade Daten...</div>
  }
  if (error) {
    return <div>{`Fehler beim Laden der Daten - ${error}`}</div>
  }
  const maschineDummy = {
    ext_sn: seriennummer
  }
  const reportsMapped = data.latest.map(baleRecord => {
    const dateHuman = new Date(baleRecord.date).toDateString()
    const link = getLinkToMaschineBaleStats(maschineDummy, baleRecord.date)
    const anzahlPresszyklen = Number(baleRecord.anzahl_presszyklen?.N) || -1
    return <li key={baleRecord.date}>
      <a href={link}>Ballen {baleRecord.baleNr} am {dateHuman}</a>
      {baleRecord.ejectionType.startsWith("before_bf")
        && <span style={{ fontSize: '11px' }}> <ConnectivityIndicator isConnected={false} name={'Vorzeitiger Auswurf'} /></span>}
      {anzahlPresszyklen > 0 && anzahlPresszyklen < 5
        && <span style={{ fontSize: '11px' }}> <ConnectivityIndicator isConnected={true} isDisabled={true} name={`Low PZ: ${anzahlPresszyklen}`} /></span>}
      {anzahlPresszyklen > 95
        && <span style={{ fontSize: '11px' }}> <ConnectivityIndicator isConnected={false} name={`High PZ: ${anzahlPresszyklen}`} /></span>}
    </li>
  })
  return <ul>{reportsMapped}</ul>
}

export default function BaleReports() {
  let params = useParams()
  const numDays = isDev
    ? 900
    : 30
  return <div style={{ display: 'flex' }}>
    <main style={{ padding: '0rem 1rem' }}>
      <h2>Ballenberichte für {params.seriennummer}</h2>
      <Breadcrumbs seriennummer={params.seriennummer} level1='reports' />

      <p>Hier finden Sie Ballenberichte für die letzten {numDays} Tage für die Maschine {params.seriennummer}. Durch Anklicken wird der Bericht geöffnet.</p>

      <BaleReportsList seriennummer={params.seriennummer} numDays={numDays} />
    </main>
  </div>
}

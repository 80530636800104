import * as React from 'react';
import { useParams } from 'react-router-dom';
// import './reports.css'

import { BaleReportsList } from './bale-reports';
import { DailyReportsList } from './daily-reports';
import { Breadcrumbs } from '../LinkUtils';

export default function Reports() {
  let params = useParams();
  return (
    <div style={{ display: 'flex' }}>
      <main style={{ padding: '0rem 1rem' }}>
        <h2>Reports für {params.seriennummer}</h2>
        <Breadcrumbs seriennummer={params.seriennummer} />

        <h3>Tägliche Auswertungen</h3>
        <DailyReportsList seriennummer={params.seriennummer} />

        <h3>Ballenberichte</h3>
        <BaleReportsList seriennummer={params.seriennummer} />
      </main>
    </div>
  );
}

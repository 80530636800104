interface ModemDetailsProps {
  maschine: any
}

export const ModemDetails = ({ maschine, ...props }: ModemDetailsProps) => {
  const modem_sn = maschine.modem_sn || maschine.seriennummer
  return (
    <div>
      <div><strong>Modem Details</strong></div>
      <div>
        Einrichtung am: {maschine.einrichtungAm ? maschine.einrichtungAm : 'unbekannt'}<br />
        Letztes Update am: {maschine.letztesUpdateAm ? maschine.letztesUpdateAm : 'unbekannt'}<br />
        Firmware Versionen: {maschine.firmwareVersion
          ? <span>
            {maschine.firmwareVersion.flows ? maschine.firmwareVersion.flows : 'unbekannt'} | {maschine.firmwareVersion['aws-jobs-agent'] ? maschine.firmwareVersion['aws-jobs-agent'] : 'unbekannt'}
          </span>
          : 'unbekannt'
        }<br />
        OS Version: {maschine.os_release && maschine.os_release.pretty_name ? maschine.os_release.pretty_name : 'unbekannt'}<br />
        {modem_sn && <>Seriennummer: {modem_sn}<br /></>}
      </div>
    </div>
  )
}

import { Flex } from '@aws-amplify/ui-react'
import { ErrorBoundary } from 'react-error-boundary'

import Fehlerliste from './Fehlerliste'
import RulesDetails from './RulesDetails'
import { ModemDetails } from './ModemDetails'
import StandortDetails from './StandortDetails'
import StatusDetails from './StatusDetails'
import VerbindungsDetails from './VerbindungsDetails'
import { SonstigeMaschinenDetails } from './SonstigeMaschinenDetails'

export default function MaschinenDetails({ maschine, ...props }) {
  // console.debug(maschine)
  return (
    <>
      <h2>Maschine: {maschine.ext_sn}</h2>
      <Flex>
        <StandortDetails maschine={maschine} />
        {/* Kennzahlen */}
        <StatusDetails maschine={maschine} />
        <SonstigeMaschinenDetails maschine={maschine} />
      </Flex>
      <br />
      <div><a href='reports/'><button className='reports-link'>Berichte ansehen</button></a></div>
      <br />
      <Flex>
        <ErrorBoundary fallback={<div>
          <strong>Verbindung</strong><br />
          Vermutlich noch nie online gewesen.
        </div>}>
          <VerbindungsDetails maschine={maschine} />
        </ErrorBoundary>
        <RulesDetails maschine={maschine} />
      </Flex>
      <br />
      <Fehlerliste maschine={maschine} maxDisplayLength={10} />
      <br />
      <ModemDetails maschine={maschine} />
    </>
  )
}
